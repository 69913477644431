$(function () {
  const changeBackground = function (getColor, textColor) {
    $('body').delay(100).attr('class', '');
    $('body').delay(300).addClass(getColor);
    $('header h1').removeClass('dark').removeClass('light').addClass(textColor);
    $('#filter').removeClass('dark').removeClass('light').addClass(textColor);
  };

  $('body').on('click', '.swatch', function () {
    // var clicked = $(this).find('div[class^="color-"]');
    var textColor = $(this).data('text');
    var getColor = $(this).children('div').attr('class');
    // var swatch = getColor.replace('color-', '');

    console.log(textColor);

    // console.log(swatch);
    console.log('click ' + getColor);
    // console.log(clicked);

    changeBackground(getColor, textColor);
    return getColor;
  });

  const search = function () {
    if ($('#filter').length) {
      $.getJSON('/assets/js/data.json', function (data) {
        var search = $('#filter').val();
        var regex = new RegExp(search, 'i');
        var output = '';

        $.each(data, function (key, val) {
          if (
            (val.title.search(regex) !== -1) ||
            (val.category[0].search(regex) !== -1) ||
            (val.category[1].search(regex) !== -1) ||
            (val.category[2].search(regex) !== -1) ||
            (val.category[3].search(regex) !== -1) ||
            (val.category[4].search(regex) !== -1) ||
            (val.category[5].search(regex) !== -1)
          ) {
            output += '<li class="swatch" data-text=' + val.text + '>';
            output += '  <div class="' + val.class + '"></div>';
            output += '  <p class="info">' + val.title + '</p>';
            output += '</li>';
          }
        });
        $('#gradientGrid').html(output);
      });
    }
  };

  const counter = function () {
    var request;

    if (request) {
      request.abort();
    }

    var referrer = window.location.href;

    console.log('Location: ', referrer);

    var serializedData = {
      url: referrer
    };

    console.log(serializedData);

    request = $.ajax({
      url: '/assets/js/counter.php',
      type: 'post',
      data: serializedData
    });

    request.done(function (response, textStatus, jqXHR) {
      // Do stuff
    });

    request.fail(function (jqXHR, textStatus, errorThrown) {
      console.error('The following error occurred: ' + textStatus, errorThrown);
    });

    // request.always(function () {
    //   // Do stuff
    // });
  };

  const getStats = function () {
    if ($('#stats').length) {
      console.log('#stats present');

      $.getJSON('/assets/js/api.php', function (data) {
        var output = '';

        // console.log(data);

        $.each(data, function (key, val) {
          console.log('Test: ', val[0]);
          output += '<li>';
          output += '  <p><span>' + val[1] + ': </span>' + val[0] + '</p>';
          output += '</li>';
        });

        console.log(output);

        $('#stats').html(output);
      });
    };
  };

  var init = function () {
    counter(); // Register page load count for analytics
    search();
    getStats();
    $('#filter').keydown(search);
  };

  $(init);
});
